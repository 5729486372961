import React from 'react';
import './style.css';

function Status(props) {

  const {completed, canceled, reservado} = props;
  if(reservado === 4){
    return <span className="uk-label status statusReservado">Reservado</span>
  }else if(!completed && !canceled){
    return <span className="uk-label status statusInProgress">Em andamento</span>
  }
  if(completed){
    return <span className="uk-label status statusComplete">Compra Finalizada</span>
  }

  if(canceled){
    return <span className="uk-label status statusCanceled">Cancelado</span>
  }
  
}

export default Status;