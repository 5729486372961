import React, { useState, useEffect } from 'react';
import { parseISO, format } from 'date-fns';
import Page from 'components/Templates/Page';
import api from 'services/api';
import './style.css';
import { useHistory, Link } from "react-router-dom";
import BtnTitle from 'components/BtnTitle';
import TagActive from 'components/TagActive';

export default function ListUnidades() {
    const history = useHistory();
    const [unidades, setUnidades] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const [paginate, setPaginate] = useState({});
    const [page, setPage] = useState(1);

    useEffect(() => {
        const loadUnidades = async () => {
            try {
                const response = await api.get(`/admin/units?limit=100`);
                setIsLoading(false);
                setPaginate(response.paginate);
                var sortingUnits = [];
                var unitsInOrder = [];
                var i;
                for (i = 0; i < response.data.length; i++) {
                    sortingUnits.push([response.data[i].number, response.data[i].enterprise.name, response.data[i].tower, response.data[i]._id])
                }
                if(i === sortingUnits.length){
                    sortingUnits.sort((a, b) => a[0] - b[0])
                        sortingUnits.sort((a, b) => {                      
                           if(a[1] < b[1]) return -1;
                           if(a[1] > b[1]) return 1;
                           if(a[2] < b[2]) return -1;
                           if(a[2] > b[2]) return 1;
                            return 0;
                        })
                }
                for(var i = 0; i < sortingUnits.length; i++){
                    response.data[i].price = Intl.NumberFormat('pt-br', {style: 'currency', currency: 'BRL'}).format(response.data[i].price);
                    unitsInOrder.push( response.data.find(unit => unit._id === sortingUnits[i][3]));
                }
                setUnidades(unitsInOrder)
            } catch (error) {
                setIsLoading(false);
                console.log(error)
            }
        }
        loadUnidades();
    }, [page])

    return (
        <Page name="unidades" loading={isLoading}>
            <h1>Unidades
                <BtnTitle label="Adicionar" iconName="plus-circle" handleOnClick={() => history.push('/empreendimentos/unidades/add')} />
            </h1>

            <div className="container">
                <table className="uk-table uk-table-striped uk-table-small  uk-table-middle">
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th>Empreendimento</th>
                            <th>Torre</th>
                            <th>Preço</th>
                            <th>Reservado</th>
                            <th>Vendido</th>
                            <th>Clonar</th>
                            <th width="40">Publicado</th>
                            <th width="130">Adicionado em:</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            unidades.map((unidade, index) => (
                                <tr key={index}>
                                    <td><Link to={`/empreendimentos/unidades/edit/${unidade._id}`}>Unidade - {unidade.number}</Link></td>
                                    <td>{unidade.enterprise.name}</td>
                                    <td>{unidade.tower}</td>
                                    <td>{unidade.price}</td>
                                    <td align="center"><TagActive active={unidade.reserved} /></td>
                                    <td align="center"><TagActive active={unidade.sold} /></td>
                                    <td align="center"><Link to={`/empreendimentos/unidades/clonar/${unidade._id}`}>Clonar</Link></td>
                                    <td align="center"><TagActive active={unidade.public} /></td>
                                    <td><i className='bx bx-calendar'></i>{format(parseISO(unidade.createdAt), 'dd/M/Y')}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>

            {/* <Pagination
                innerClass="pagination"
                activePage={Number(paginate.page)}
                totalItemsCount={Number(paginate.total)}
                pageRangeDisplayed={10}
                hideNavigation={true}
                hideFirstLastPages={true}
                onChange={(page) => {
            
                    setPage(page);
                }}
            /> */}

        </Page>
    )
}
